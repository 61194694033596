<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline">Distribute</span>
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text
          class="pb-0"
          style="display: flex; flex-direction: column;"
        >
          <div class="d-flex flex-row mt-3">
            <editable-avatar
              :editable="false"
              :size="60"
              :image="client.image"
            />
            <div class="d-flex flex-column justify-center ml-3">
              <div style="font-weight: bold;">{{ client.client_id }}</div>
              <div>{{ client.first_name }} {{ client.last_name }}</div>
            </div>
          </div>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :disabled="loading"
                  :items="freezers"
                  background-color="white"
                  label="Select Freezer"
                  :filter="searchFilter"
                  @change="onSelectedFreezer"
                  :error-messages="searchError"
                  :hide-details="!searchError"
                  append-icon="mdi-magnify"
                  class="notranslate"
                  outlined
                  dense
                >
                  <template v-slot:selection="data">
                    {{
                      data.item.organization
                        ? data.item.organization
                        : data.item.first_name +
                          (data.item.last_name ? " " + data.item.last_name : "")
                    }}
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <editable-avatar
                        :image="data.item.image"
                        :size="40"
                        :editable="false"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.organization" />
                      <v-list-item-subtitle
                        v-html="
                          data.item.first_name +
                            (data.item.last_name
                              ? ' ' + data.item.last_name
                              : '') +
                            '  ' +
                            formatPhoneNumber(data.item.phone)
                        "
                      />
                      <v-list-item-subtitle v-html="data.item.email" />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :disabled="loading"
                  :items="balances"
                  background-color="white"
                  label="Select Food"
                  :filter="searchFoodFilter"
                  @change="onSelectedFood"
                  :error-messages="searchError"
                  :hide-details="!searchError"
                  append-icon="mdi-magnify"
                  class="notranslate"
                  outlined
                  dense
                >
                  <template v-slot:selection="data">
                    {{ data.item.food.name }}
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img
                        :src="
                          data.item.food.image
                            ? data.item.food.image
                            : placeholderImage
                        "
                        :width="140"
                        :height="100"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.food.name" />
                      <v-list-item-subtitle
                        v-html="'Amount: ' + data.item.amount"
                      />
                      <v-list-item-title
                        v-html="data.item.food.meals + ' Serving'"
                      />
                      <v-list-item-title
                        v-html="
                          data.item.food.serving_size +
                            ' ' +
                            data.item.food.unit
                        "
                      />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="amount"
                  label="Amount"
                  type="number"
                  @onChange="onChange"
                  required
                  dense
                  outlined
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <VueCtkDateTimePicker
                  v-model="date"
                  format="MM/DD/YYYY hh:mm a"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            @click="onDistribute"
            :disabled="!selectedFreezer || !amount"
          >
            Distribute
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";

export default {
  components: {
    EditableAvatar,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    client: Object,
    onCloseDialog: {
      type: Function,
    },
    onDistributed: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      statusOptions: ["Yes", "No", "Urgent Help", "No response"],
      error: null,
      loading: false,
      searchError: null,
      selectedFreezer: null,
      selectedFood: null,
      amount: null,
      date: null,
      placeholderImage: require("@/assets/default_image.png"),
    };
  },
  methods: {
    ...mapActions({
      distribute: "distribution/distribute",
      getFreezers: "freezer/fetchAllFreezers",
      fetchAllFreezerBalances: "freezer/fetchAllFreezerBalances",
      fetchAllFoods: "food/fetchAllFoods",
    }),
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    onDistribute() {
      this.loading = true;
      if (!this.selectedFreezer || !this.amount) return;

      this.distribute({
        freezer: this.selectedFreezer._id,
        client_id: this.client.client_id,
        amount: this.amount,
        food_id: this.selectedFood.food._id,
        type: "Food",
        date: this.date,
      })
        .then((res) => {
          this.loading = false;
          this.error = null;
          this.onDistributed(res);
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
    onClose() {
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    searchFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      if (
        Object.keys(item).some(
          (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
        )
      ) {
        return true;
      }
      if (`${item.first_name} ${item.last_name}`.includes(searchText)) {
        return true;
      }
      return false;
    },
    searchFoodFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      if (
        Object.keys(item).some(
          (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
        )
      ) {
        return true;
      }
      if (`${item.name}`.includes(searchText)) {
        return true;
      }
      return false;
    },
    onSelectedFreezer(item) {
      this.selectedFreezer = item;
      this.fetchAllFreezerBalances({ _id: item._id })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    onSelectedFood(item) {
      this.selectedFood = item;
    },
  },
  computed: {
    ...mapGetters({
      freezers: "freezer/getAllFreezers",
      foods: "food/getAllFoods",
      balances: "freezer/getAllBalances",
    }),
  },
  mounted() {
    this.loading = true;
    this.getFreezers()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.fetchAllFoods().catch((error) => {
      console.log(error.response.data.message);
    });
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate {
  transform: none !important;
}
</style>
