<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog v-model="dialog" persistent max-width="750px">
      <v-card>
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline">Intake Access History</span>
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          Employees who had access to this client data.
        </v-card-text>
        <v-card-text
          class="pb-0"
          style="display: flex; flex-direction: column;"
        >
          <v-data-table :items="histories" :headers="headers">
            <template v-slot:[`item.image`]="{ item }">
              <v-avatar class="elevation-6 my-3 ml-5">
                <v-img :src="item.image ? item.image : placeholderImage" />
              </v-avatar>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <div style="width: 120px">
                {{ getTimeAgo(item.date) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import { mapActions } from "vuex";
import dateFormat from "dateformat";

export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    client_id: String,
    partner_id: String,
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Description", value: "text" },
        { text: "Date", value: "date" },
      ],
      histories: [],
      placeholderImage: require("@/assets/default.jpg"),
    };
  },
  methods: {
    ...mapActions({
      getHistories: "client/getHistories",
    }),
    onLoadData() {
      this.loading = true;
      this.getHistories({
        client_id: this.client_id,
        partner_id: this.partner_id,
      })
        .then((res) => {
          this.loading = false;
          this.histories = res;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
    onClose() {
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    getTimeAgo(oldTimeStr) {
      var date = new Date(oldTimeStr);
      var dateStr = dateFormat(date, "mm/dd h:MM TT");
      return dateStr;
    },
  },
  mounted() {
    this.onLoadData();
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate {
  transform: none !important;
}
</style>
