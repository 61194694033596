<template>
  <div class="left-section">
    <div style="width: 280px; max-width: 280px;" v-if="profile != null">
      <div>
        <v-card-text
          class="pt-4 pb-3 d-flex align-center"
          style="position: relative"
        >
          <div v-if="onClosePanel">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onClosePanel"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </template>
              Close detail panel
            </v-tooltip>
          </div>
          <label class="client_title ml-4">Client - Details</label>
          <!-- <v-btn x-small outlined color="primary" @click="goToDistributions"
            ><v-icon size="14" class="mr-1">mdi-history</v-icon>History</v-btn
          > -->
          <v-menu offset-y rounded="lg" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="position: absolute; right: 15px; top: 10px"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in dropMenus"
                v-on:change="onSelectedMenu(item)"
                :key="index"
                link
              >
                <v-list-item-title class="mr-3">
                  <v-icon v-text="item.icon" class="mr-3" />
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-text>
        <v-card-title class="justify-center pt-0 pb-2">
          <rounded-editable-avatar
            :image="profile.image"
            :onImageSelected="onImageSelected"
            :editable="isEditing"
            :preview="profile.image ? true : false"
            :deletable="true"
            :onDelete="onDeleteImage"
          />
        </v-card-title>
        <v-card-title
          class="align-center ml-3"
          style="justify-content: space-around"
          v-if="!loadingData"
        >
          <tool-tip-image
            :tooltips="'Age: ' + age"
            :src="require('@/assets/age.svg')"
            v-if="age"
          />
          <tool-tip-image
            tooltips="Age not set"
            :src="require('@/assets/age_disabled.svg')"
            v-else
          />
          <tool-tip-image
            tooltips="Male"
            :src="require('@/assets/male.svg')"
            v-if="gender == 'Male'"
          />
          <tool-tip-image
            tooltips="Female"
            :src="require('@/assets/female.svg')"
            v-else-if="gender == 'Female'"
          />
          <tool-tip-image
            tooltips="Gender not set"
            :src="require('@/assets/gender.svg')"
            v-else
          />
          <tool-tip-image
            tooltips="Have internet"
            :src="require('@/assets/internet.svg')"
            v-if="internet == 'true'"
          />
          <tool-tip-image
            tooltips="No internet"
            :src="require('@/assets/no_internet.svg')"
            v-else-if="internet == 'false'"
          />
          <tool-tip-image
            tooltips="Internet not set"
            :src="require('@/assets/internet_disabled.svg')"
            v-else
          />
          <tool-tip-image
            tooltips="Have transportation"
            :src="require('@/assets/transportation.svg')"
            v-if="transportation == 'true'"
          />
          <tool-tip-image
            tooltips="no transportation"
            :src="require('@/assets/no_transportation.svg')"
            v-else-if="transportation == 'false'"
          />
          <tool-tip-image
            tooltips="Transportation not set"
            :src="require('@/assets/transportation_disabled.svg')"
            v-else
          />
          <tool-tip-image
            :tooltips="
              household +
                ' total' +
                (!this.adults
                  ? ''
                  : this.adults == 1
                  ? ', 1 adult'
                  : ', ' + this.adults + ' adults') +
                (!this.children
                  ? ''
                  : this.children == 1
                  ? ', 1 child'
                  : ', ' + this.children + ' children')
            "
            :src="require('@/assets/family.svg')"
            v-if="household"
          />
          <tool-tip-image
            tooltips="Family not set"
            :src="require('@/assets/family_disabled.svg')"
            v-else
          />
          <tool-tip-image
            :tooltips="
              `Already receiving assistance${
                from_where ? ' from ' + from_where : ''
              }`
            "
            :src="require('@/assets/get_help.svg')"
            v-if="get_help == 'true'"
          />
          <tool-tip-image
            tooltips="No Assistance"
            :src="require('@/assets/no_get_help.svg')"
            v-else-if="get_help == 'false'"
          />
          <tool-tip-image
            tooltips="Assistance not set"
            :src="require('@/assets/get_help_disabled.svg')"
            v-else
          />
          <!-- <tool-tip-image
            :tooltips="
              `${partnerName ? 'Partner name: ' + partnerName : 'Have partner'}`
            "
            :src="require('@/assets/partner.svg')"
            v-if="partner == 'true'"
          />
          <tool-tip-image
            tooltips="Partner not set"
            :src="require('@/assets/partner_disabled.svg')"
            v-else
          /> -->
        </v-card-title>
        <v-card-title v-else style="margin-top:6px;">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
            class="ml-4 my-1"
          />
        </v-card-title>
        <v-card-title class="mx-3" v-if="isEditing">
          <v-text-field
            v-model="profile.email"
            label="Email Address"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
          />
          <v-text-field
            v-model="profile.first_name"
            label="First Name"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
            class="mt-3"
          />
          <v-text-field
            v-model="profile.last_name"
            label="Last Name"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
            class="mt-3"
          />
          <div class="d-flex flex-row align-center">
            <v-text-field
              v-model="profile.phone"
              label="Phone"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
            <v-btn
              class="mb-3 ml-3"
              rounded
              small
              @click="verifyPhone"
              v-if="!profile.phone_verified"
              :loading="sendingCode"
              :disabled="codeTime != 0"
            >
              {{ codeTime ? `(${codeTime})` : sentCode ? "resend" : "verify" }}
            </v-btn>
          </div>
          <div
            class="d-flex flex-row align-center"
            v-if="sentCode && !phone_verified"
          >
            <v-text-field
              v-model="verificationCode"
              label="Code"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
            <v-btn
              class="mb-3 ml-3"
              rounded
              small
              @click="confirmCode"
              :disabled="verificationCode.length != 4"
              :loading="confirmingCode"
            >
              confirm
            </v-btn>
          </div>
          <v-text-field
            v-model="profile.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.min]"
            @click:append="showPassword = !showPassword"
            @change="onChange"
            label="Password"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            class="pb-0 mb-0 mt-3"
          />
          <v-alert type="error" v-if="errorMessage">
            {{ errorMessage }}
          </v-alert>
          <vuetify-google-autocomplete
            id="map"
            label="Address"
            placeholder=""
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            v-model="profile.address"
            v-on:placechanged="getAddressData"
            class="mt-3"
            ref="address"
          />
          <v-text-field
            v-model="profile.address2"
            label="Address 2"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
            class="mt-3"
          />
          <v-text-field
            v-model="profile.city"
            label="City"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
            class="mt-3"
          />
          <v-text-field
            v-model="profile.state"
            label="State"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
            class="mt-3"
          />
          <v-text-field
            v-model="profile.zip"
            label="Zip"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
            class="mt-3"
          />
          <v-textarea
            v-model="profile.other_info"
            label="Other Info"
            rows="1"
            auto-grow
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
            class="mt-3"
          />
          <v-select
            v-model="profile.status"
            :items="statusOptions"
            label="Status"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            @change="onChange"
            class="mt-3"
          />
        </v-card-title>
        <v-card-title
          v-else
          class="d-flex flex-column mt-0 pt-0"
          style="align-items: flex-start; padding-start: 30px;"
        >
          <div>
            <span class="item-label">ID: </span>
            <span class="item-value">{{ profile.client_id }}</span>
          </div>
          <div>
            <span class="item-label">Name: </span>
            <span class="item-value"
              >{{ profile.first_name }} {{ profile.last_name }}</span
            >
          </div>
          <div>
            <span class="item-label">Phone: </span>
            <span class="item-value">{{
              formatPhoneNumber(profile.phone)
            }}</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="profile.phone_verified"
                  size="14"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-shield-check</v-icon
                >
              </template>
              <span>Verified phone number</span>
            </v-tooltip>
          </div>
          <div>
            <span class="item-label">Email: </span>
            <span class="item-value">{{ profile.email }}</span>
          </div>
          <div class="d-flex flex-row">
            <div class="item-label" style="min-width: 65px">Address:</div>
            <span class="item-value"
              >{{ profile.address }},
              {{ profile.address2 ? profile.address2 + "," : "" }}
              {{ profile.city }}, {{ profile.state }} {{ profile.zip }}</span
            >
          </div>
          <div>
            <span class="item-label">Status: </span>
            <span class="item-value">{{ profile.status }}</span>
          </div>
          <div class="d-flex justify-between w-100">
            <div>
              <span class="item-label">Last checked in: </span>
              <span
                class="item-value cursor-pointer"
                v-if="profile.last_checked_in"
                @click="onShowAllCheckedIn"
              >
                {{ formatDate(profile.last_checked_in) }}
              </span>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  fab
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  @click="onShowAllCheckedIn"
                  ><v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              Go to checked in list
            </v-tooltip>
          </div>
          <div v-if="profile.other_info">
            <span class="item-label">Other Info: </span>
            <span class="item-value">{{ profile.other_info }}</span>
          </div>
          <div>
            <span class="item-label">Barcode: </span>
            <div class="item-value" style="margin-left: 20px;">
              <barcode
                :value="profile.client_id"
                :options="{ displayValue: false }"
                tag="svg"
              />
            </div>
          </div>
        </v-card-title>
        <v-card-text class="pt-0 " v-if="balance && balance.length != 0">
          <label class="request_description_title ml-4">
            Request content
          </label>
        </v-card-text>
        <div v-for="(item, index) in balance" :key="index">
          <v-card-text class="pt-0 pb-0">
            <div
              class="request_description_item ml-4"
              style="display:flex;justify-content:space-between;align-item:center;"
            >
              <div v-if="!isEditing" class="pb-2">{{ item.type }}</div>
              <div class="mr-6 pb-2" v-if="!isEditing">
                {{ item.amount }}
              </div>
              <v-text-field
                v-model="item.amount"
                :label="item.type"
                dense
                rounded
                outlined
                persistent-hint
                background-color="white"
                @change="onChange"
                class="mt-3"
                v-else
              />
            </div>
          </v-card-text>
          <v-divider class="mx-4" />
        </div>
        <v-card-text
          class="pt-2 pb-0"
          v-if="
            !isEditing && (profile.first_intake_date || profile.intake_date)
          "
        >
          <div
            class="request_description_item ml-4"
            style="display:flex;justify-content:space-between;align-item:center;"
          >
            <div class="pb-2 item-label">First Intake:</div>
            <div class="mr-6 pb-2" v-if="!isEditing">
              {{
                getTimeAgo(
                  profile.first_intake_date
                    ? profile.first_intake_date
                    : profile.intake_date
                )
              }}
            </div>
          </div>
        </v-card-text>
        <v-card-text class="pt-2 pb-0" v-if="!isEditing && profile.intake_date">
          <div
            class="request_description_item ml-4 pb-5"
            style="display:flex;justify-content:space-between;align-item:center;"
          >
            <div class="pb-2 item-label">Last Intake:</div>
            <div class="mr-6 pb-2" v-if="!isEditing">
              {{ getTimeAgo(profile.intake_date) }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center mb-3" v-if="isEditing">
          <v-btn
            small
            rounded
            color="#7024C4"
            dark
            @click="onSave"
            outlined
            :loading="isEditingClient"
          >
            <v-icon color="#7024C4" size="18" class="mr-1"
              >mdi-content-save</v-icon
            >
            Save
          </v-btn>
          <v-btn small rounded color="#7024C4" dark @click="onClose" outlined>
            <v-icon color="#7024C4" size="18" class="mr-1">mdi-close</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
        <v-card-actions
          class="justify-center mb-3"
          v-else
          style="display: none;"
        >
          <v-btn
            small
            rounded
            color="#7024C4"
            dark
            @click="isEditing = true"
            outlined
            :loading="isEditingClient"
          >
            <v-icon color="#7024C4" size="18" class="mr-1"
              >mdi-pencil-outline</v-icon
            >
            Edit
          </v-btn>
          <v-btn
            small
            rounded
            color="#7024C4"
            dark
            @click="onDelete"
            outlined
            :loading="isDeletingClient"
          >
            <v-icon color="#7024C4" size="18" class="mr-1">mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-btn
            small
            rounded
            color="#7024C4"
            dark
            @click="onIntake"
            outlined
            v-if="onIntake"
          >
            <v-icon
              color="#7024C4"
              size="18"
              class="mr-1"
              v-text="'mdi-account-question'"
            />
            Intake
          </v-btn>
        </v-card-actions>
      </div>
    </div>
    <v-dialog v-model="deleteDialog" max-width="520px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this client? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteImageDialog" max-width="520px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete the image? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteImageDialog = !deleteImageDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteImageAction"
            :loading="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <admin-distribution-dialog
      :dialog="distributeDialog"
      :client="profile"
      v-if="profile && distributeDialog"
      :onCloseDialog="onCloseDialog"
      :onDistributed="onDistributed"
    />
    <intake-access-history-dialog
      :dialog="accessHistoryDialog"
      :client_id="profile._id"
      v-if="profile && accessHistoryDialog"
      :onCloseDialog="onCloseDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RoundedEditableAvatar from "../core/RoundedEditableAvatar.vue";
import ToolTipImage from "../core/ToolTipImage.vue";
import dateFormat from "dateformat";
import AdminDistributionDialog from "../admin/AdminDistributionDialog.vue";
import IntakeAccessHistoryDialog from "./IntakeAccessHistoryDialog.vue";
export default {
  components: {
    RoundedEditableAvatar,
    ToolTipImage,
    AdminDistributionDialog,
    IntakeAccessHistoryDialog,
  },
  props: {
    id: String,
    onIntake: Function,
    onDeleted: Function,
    onClosePanel: Function,
  },
  data() {
    return {
      profile: null,
      selectedFile: null,
      errorMessage: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      showPassword: false,
      statusOptions: [
        // "No",
        // "Yes",
        // "Urgent Help",
        // "Intake Completed",
        // "No response",
        "Active",
        "Inactive",
      ],
      isEditing: false,
      loading: false,
      loadingData: false,
      deleteDialog: false,
      deleteImageDialog: false,
      age: null,
      gender: null,
      internet: null,
      transportation: null,
      family: null,
      get_help: null,
      // partner: null,
      // partnerName: null,
      from_where: null,
      household: null,
      ages: [],
      adults: 0,
      children: 0,
      balance: [],
      families: {},
      isEditingClient: false,
      isDeletingClient: false,
      snackbar: false,
      snackMessage: null,
      codeTime: 0,
      sendingCode: false,
      confirmingCode: false,
      sentCode: false,
      verificationCode: "",
      dropMenus: [
        { title: "Distribution History", icon: "mdi-history" },
        { title: "Edit Client", icon: "mdi-pencil-outline" },
        { title: "Delete Client", icon: "mdi-delete" },
        { title: "Intake", icon: "mdi-account-question" },
        { title: "Distribute", icon: "mdi-package" },
      ],
      distributeDialog: false,
      accessHistoryDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      intakes: "intake/getAllIntakes",
      allQuestions: "question/getAllQuestions",
      profileType: "auth/getType",
    }),
  },
  methods: {
    ...mapActions({
      getClient: "client/getClient",
      editClient: "client/editClient",
      checkInClient: "client/checkInClient",
      deleteClient: "client/deleteClient",
      deleteClientImage: "client/deleteClientImage",
      getAllQuestions: "question/fetchAllQuestions",
      getAllIntakes: "intake/fetchAllIntakes",
      setSelectedId: "intake/setSelectedId",
      setSelectedClientId: "intake/setSelectedClientId",
      setBalance: "client/setBalance",
      sendVerificationCode: "client/sendVerificationCode",
      confirmPhoneVerificationCode: "client/verifyPhone",
      createNewConversation: "chat/createNewConversation",
    }),
    onImageSelected(file) {
      this.selectedFile = file;
    },
    onChange() {
      this.errorMessage = null;
    },
    getAddressData(addressData) {
      this.profile.address = addressData.name;
      this.profile.state = addressData.administrative_area_level_1;
      this.profile.city = addressData.locality;
      this.profile.zip = addressData.postal_code;
      this.profile.location =
        addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.profile.address);
    },
    onSave() {
      this.isEditingClient = true;
      var formData = new FormData();
      Object.keys(this.profile).map((key) => {
        if (this.profile[key]) formData.append(key, this.profile[key]);
      });
      if (this.selectedFile) formData.append("image", this.selectedFile);

      this.balance.map((b) => {
        formData.append(b.type, b.amount);
      });

      if (this.profile.rest_balance && this.profile.rest_balance.length > 0) {
        Object.keys(this.profile.rest_balance).map((key) => {
          formData.append(key, this.profile.rest_balance[key]);
        });
      }
      this.editClient(formData)
        .then(() => {
          this.loading = false;
          this.selected = 0;
          this.isEditingClient = false;
          this.isEditing = false;
        })
        .catch((error) => {
          this.loading = false;
          this.isEditingClient = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
            this.onShowSnackbar(error);
          }
        });
    },
    onDeleteImage() {
      this.deleteImageDialog = true;
    },
    deleteImageAction() {
      this.deleteClientImage({ _id: this.profile._id })
        .then((res) => {
          this.profile = res;
          this.deleteImageDialog = false;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    onClose() {
      this.isEditing = false;
    },
    onShowSnackbar(error) {
      this.snackbar = true;
      this.snackMessage = error.response.data.message;
    },
    analyseData() {
      this.age = null;
      this.gender = null;
      this.internet = null;
      this.transportation = null;
      this.family = null;
      this.get_help = null;
      this.from_where = null;
      this.household = null;
      this.ages = [];
      this.adults = 0;
      this.children = 0;
      this.families = {};
      Object.keys(this.intakes).map((key) => {
        const intake = this.intakes[key];
        const question_id = key.split("_")[0];
        const index = key.split("_")[1];
        for (var i = 0; i < this.allQuestions.length; i++) {
          if (question_id == this.allQuestions[i]._id) {
            if (
              this.allQuestions[i].key.includes("already_receiving_assistance")
            ) {
              this.get_help = intake;
              break;
            } else if (this.allQuestions[i].key == "from_where") {
              this.from_where = intake.replaceAll(",", ", ");
              break;
            } else if (this.allQuestions[i].key == "birthday" && index == 1) {
              this.age = this.getAge(intake);
              break;
            } else if (this.allQuestions[i].key == "gender" && index == 1) {
              this.gender = intake;
              break;
            } else if (this.allQuestions[i].key == "do_you_have_internet_") {
              this.internet = intake;
              break;
            } else if (
              this.allQuestions[i].key == "do_you_have_transportation_"
            ) {
              this.transportation = intake;
              break;
            } else if (
              this.allQuestions[i].key == "how_many_people_in_your_household_"
            ) {
              this.household = intake;
              break;
            } else if (
              this.allQuestions[i].parent == "60adb8e8455d9faa10ae54ce" &&
              this.allQuestions[i].key == "birthday"
            ) {
              this.ages[index - 1] = this.getAge(intake);
              this.adults = 0;
              this.children = 0;
              this.ages.map((age) => {
                if (age < 18) this.children++;
                else this.adults++;
              });
            }

            if (this.allQuestions[i].parent == "60adb8e8455d9faa10ae54ce") {
              if (!this.families[index]) {
                this.families[index] = { index };
              }
              this.families[index][this.allQuestions[i].key] = intake;
            }
          }
        }
      });
    },
    getAge(value) {
      var today = new Date();
      var birthDate = new Date(value);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    onShowAllCheckedIn() {
      if (this.$route.name != "checked-ins") {
        let routeData = this.$router.resolve({
          name: "checked-ins",
          params: { id: this.profile._id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.isDeletingClient = true;
      this.deleteClient(this.profile)
        .then(() => {
          this.isDeletingClient = false;
          this.deleteDialog = false;
          if (this.onDeleted) this.onDeleted();
        })
        .catch((error) => {
          this.isDeletingClient = false;
          this.deleteDialog = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
            this.onShowSnackbar(error);
          }
        });
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    getData() {
      if (!this.id) return;
      this.getClient(this.id).then((res) => {
        this.profile = res;
        this.setSelectedClientId(this.profile.client_id);
      });
      this.setSelectedId(this.id);
      this.loadingData = true;
      this.getAllQuestions()
        .then(() => {
          this.getAllIntakes({ date: this.$route.query.date })
            .then(() => {
              this.loadingData = false;
              this.analyseData();
            })
            .catch((error) => {
              this.loadingData = false;
              console.log(error.response.data.message);
              this.snackMessage = error.response.data.message;
              this.snackbar = true;
            });
        })
        .catch((error) => {
          this.loadingData = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
      this.getClient(this.id).then((res) => {
        this.profile = res;
        this.balance = [];
        if (this.profile.rest_balance) {
          Object.keys(this.profile.rest_balance).map((key) => {
            var a = {};
            a.type = key;
            a.amount = this.profile.rest_balance[key];
            if (key != "meals") {
              this.balance.push(a);
            }
          });
        }
      });
    },
    goToDistributions() {
      this.$router.push({
        name: "distributions",
        query: {
          to: this.profile.client_id,
        },
      });
    },
    onDistributed() {
      this.distributeDialog = false;
      this.getData();
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    formatDate(dateStr) {
      var today = new Date();
      var date = new Date(dateStr);
      var todayFormat = "hh:MM TT";
      var weekFormat = "ddd hh:MM TT";
      var dayFormat = "mm/dd hh:MM TT";
      var yearFormat = "mm/dd/yyyy";
      var diffTime = today.getTime() - date.getTime();
      var diffDay = diffTime / (1000 * 3600 * 24);
      if (diffDay < 1) {
        return dateFormat(
          this.convertTZ(date, this.profile.timezone),
          todayFormat
        );
      } else if (diffDay < 6) {
        return dateFormat(
          this.convertTZ(date, this.profile.timezone),
          weekFormat
        );
      } else {
        if (
          dateFormat(this.convertTZ(today, this.profile.timezone), "yyyy") ==
          dateFormat(this.convertTZ(date, this.profile.timezone), "yyyy")
        ) {
          return dateFormat(
            this.convertTZ(date, this.profile.timezone),
            dayFormat
          );
        } else {
          return dateFormat(
            this.convertTZ(date, this.profile.timezone),
            yearFormat
          );
        }
      }
    },
    verifyPhone() {
      this.sendingCode = true;
      this.sendVerificationCode({ _id: this.profile._id })
        .then(() => {
          this.sendingCode = false;
          this.sentCode = true;
          this.codeTime = 60;
          this.countDownCodeTime();
        })
        .catch((error) => {
          this.sendingCode = false;
          console.log(error.response.data.message);
        });
    },
    confirmCode() {
      this.confirmingCode = true;
      this.confirmPhoneVerificationCode({
        code: this.verificationCode,
        _id: this.profile._id,
      })
        .then(() => {
          this.confirmingCode = false;
          this.profile.phone_verified = true;
          this.sentCode = false;
        })
        .catch((error) => {
          this.confirmingCode = false;
          console.log(error.response.data.message);
        });
    },
    countDownCodeTime() {
      if (this.codeTime > 0) {
        this.codeTime--;
        setTimeout(this.countDownCodeTime, 1000);
      }
    },
    getTimeAgo(oldTimeStr) {
      var date = new Date(oldTimeStr);
      var dateStr = dateFormat(date, "mm/dd/yyyy");
      return dateStr;
    },
    onSelectedMenu(item) {
      if (item.title == "Delete Client") {
        this.onDelete();
      } else if (item.title == "Edit Client") {
        this.isEditing = true;
      } else if (item.title == "Intake") {
        this.onIntake();
      } else if (item.title == "Distribution History") {
        this.goToDistributions();
      } else if (item.title == "Distribute") {
        this.onDistribute();
      } else if (item.title == "Intake Access History") {
        this.accessHistoryDialog = true;
      } else if (item.title == "Chat") {
        this.createNewConversation({
          user_id: this.profile._id,
          user_type: "Client",
        })
          .then((res) => {
            this.$router.push({
              name: "chat-details",
              params: { id: res._id },
            });
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
          });
      } else if (item.title == "Tag Reports") {
        let routeData = this.$router.resolve({
          name: "tagReports",
          params: { id: this.profile._id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    onDistribute() {
      this.distributeDialog = true;
    },
    onCloseDialog() {
      this.distributeDialog = false;
      this.accessHistoryDialog = false;
    },
  },
  watch: {
    id: function() {
      this.getData();
    },
    profileType(newValue) {
      if (newValue) {
        if (newValue == "Admin") {
          this.dropMenus = [
            { title: "Distribution History", icon: "mdi-history" },
            { title: "Edit Client", icon: "mdi-pencil-outline" },
            { title: "Delete Client", icon: "mdi-delete" },
            { title: "Intake", icon: "mdi-account-question" },
            { title: "Distribute", icon: "mdi-package" },
            { title: "Chat", icon: "mdi-message-outline" },
          ];
        } else {
          this.dropMenus = [
            { title: "Intake", icon: "mdi-account-question" },
            { title: "Chat", icon: "mdi-message-outline" },
            // { title: "Distribute", icon: "mdi-package" },
          ];
        }
      }
    },
  },
  mounted() {
    this.getData();
    if (this.profileType == "Admin") {
      this.dropMenus = [
        { title: "Distribution History", icon: "mdi-history" },
        { title: "Edit Client", icon: "mdi-pencil-outline" },
        { title: "Delete Client", icon: "mdi-delete" },
        { title: "Intake", icon: "mdi-account-question" },
        { title: "Distribute", icon: "mdi-package" },
        {
          title: "Intake Access History",
          icon: "mdi-clipboard-text-clock-outline",
        },
        {
          title: "Tag Reports",
          icon: "mdi-file-chart-outline",
        },
        { title: "Chat", icon: "mdi-message-outline" },
      ];
    } else {
      this.dropMenus = [
        // { title: "Distribution History", icon: "mdi-history" },
        { title: "Intake", icon: "mdi-account-question" },
        { title: "Chat", icon: "mdi-message-outline" },
        // { title: "Distribute", icon: "mdi-package" },
      ];
    }
  },
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 70px;
  max-width: 320px;
  min-width: 320px;
  width: 320px;
  background: white;
  z-index: 11;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.item-label {
  font-family: "Poppins-SemiBold";
  font-size: 12px;
  color: #472583;
}
.item-value {
  font-family: "Poppins-Regular";
  font-size: 12px;
  color: #423f63;
  word-break: break-word;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.client_title {
  color: #747474;
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}
</style>
