<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-img
          v-bind="attrs"
          v-on="on"
          :src="src"
          width="20"
          height="20"
          contain
        />
      </template>
      <span>{{ tooltips }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    tooltips: {
      type: String,
      default: "",
    },
    src: String,
  },
};
</script>
