<template>
  <v-container fluid style="margin: 0px; padding: 0px;height: 100%;">
    <Menu
      style="position:fixed; height: 50px; z-index: 1; left: 0; right: 0; background: #DBC7FF"
      title="Back"
      :question="true"
      :nutri_info="true"
      :summary="true"
      :intake_date="true"
      :handleBack="handleBack"
    />
    <div
      style="background: #F4F9FF; height: 100%; margin:0px; padding-top:50px; min-height: calc(100vh - 120px)"
      class="d-flex flex-row"
    >
      <client-info-card
        :id="this.$route.params.id"
        :onDeleted="handleBack"
        ref="infoCard"
      />
      <div style="width: 100%; height: 100%; overflow-y: auto;">
        <div class="d-flex mr-3 mt-3">
          <highcharts
            :options="chartOptions1"
            style="height:280px; width: 33%"
            ref="chart1"
          />
          <highcharts
            :options="chartOptions2"
            style="height:280px; width: 33%"
            ref="chart2"
          />
          <highcharts
            :options="chartOptions3"
            style="height:280px; width: 33%"
            ref="chart3"
          />
        </div>
        <div class="mt-5">
          <highcharts
            :options="barChartOptions"
            style="height:280px;"
            ref="barChart"
          />
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "@/components/core/Menu.vue";
import { mapActions } from "vuex";
import ClientInfoCard from "@/components/client/ClientInfoCard.vue";

export default {
  components: {
    Menu,
    ClientInfoCard,
  },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
      profile: null,
      chartOptions1: {
        showInLegend: true,
        chart: {
          renderTo: "container",
          // plotBackgroundColor: null,
          // plotBorderWidth: null,
          // plotShadow: false,
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "-", //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "Brands",
            colors: [],
            data: [],
          },
        ],
      },
      chartOptions2: {
        showInLegend: true,
        chart: {
          renderTo: "container",
          // plotBackgroundColor: null,
          // plotBorderWidth: null,
          // plotShadow: false,
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "-", //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "Brands",
            colors: [],
            data: [],
          },
        ],
      },
      chartOptions3: {
        showInLegend: true,
        chart: {
          renderTo: "container",
          // plotBackgroundColor: null,
          // plotBorderWidth: null,
          // plotShadow: false,
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Overall", //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: "Brands",
            colors: [],
            data: [],
          },
        ],
      },
      barChartOptions: {
        showInLegend: true,
        chart: {
          renderTo: "container",
          // plotBackgroundColor: null,
          // plotBorderWidth: null,
          // plotShadow: false,
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Timeline", //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.0f}%</b><br/>',
          shared: true,
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        plotOptions: {
          column: {
            stacking: "percent",
          },
        },
        series: [],
      },
    };
  },
  methods: {
    ...mapActions({
      setSelectedId: "intake/setSelectedId",
      getClient: "client/getClient",
      getTagReports: "distribution/getTagReports",
      getAllTagReports: "distribution/getAllTagReports",
      getValues: "variable/getValues",
    }),
    handleBack() {
      this.$router.push({ name: "clientsHome" });
    },
  },
  watch: {
    "$route.query": function(newValue) {
      this.getAllIntakes({ date: newValue.date }).catch((error) =>
        console.log(error.response.data.message)
      );
    },
  },
  mounted() {
    this.loading = true;
    this.setSelectedId(this.$route.params.id);
    this.$refs.chart1.chart.showLoading();
    this.$refs.chart2.chart.showLoading();
    this.$refs.chart3.chart.showLoading();
    this.$refs.barChart.chart.showLoading();
    this.getValues({ title: "Food Tags" })
      .then((values) => {
        const tags = values.map((value) => {
          return JSON.parse(value.text);
        });
        let colorTitles = { gray: "Not tagged" };
        let colorKeys = tags.map((tag) => tag.color);
        let colorIndexes = {};
        for (let i = 0; i < tags.length; i++) {
          colorTitles[`${tags[i].color}`] = colorTitles[`${tags[i].color}`]
            ? colorTitles[`${tags[i].color}`] + ", " + tags[i].title
            : tags[i].title;
        }
        colorKeys.push("gray");
        colorKeys = colorKeys.filter(
          (item, index) => colorKeys.indexOf(item) === index
        );
        for (let i = 0; i < colorKeys.length; i++) {
          colorIndexes[colorKeys[i]] = i;
        }
        console.log(colorTitles);
        console.log(colorKeys);

        this.getTagReports({ _id: this.$route.params.id })
          .then((res) => {
            this.loading = false;
            this.$refs.chart1.chart.hideLoading();
            this.$refs.chart2.chart.hideLoading();
            this.$refs.barChart.chart.hideLoading();
            this.barChartOptions.xAxis.categories = [];
            this.barChartOptions.series = [];
            for (let i = 0; i < colorKeys.length; i++) {
              this.barChartOptions.series.push({
                name: colorTitles[colorKeys[i]],
                color:
                  colorKeys[i] == "red"
                    ? "#F44336"
                    : colorKeys[i] == "green"
                    ? "#4CAF50"
                    : colorKeys[i] == "yellow"
                    ? "#ffeb3b"
                    : colorKeys[i] == "gray"
                    ? "lightgray"
                    : colorKeys[i],
                data: [],
              });
            }
            for (let i = 0; i < res.length; i++) {
              this.barChartOptions.xAxis.categories.push(res[i]._id);
              for (let j = 0; j < res[i].series.length; j++) {
                this.barChartOptions.series[
                  colorIndexes[res[i].series[j].tag]
                ].data.push(res[i].series[j].count);
              }
              if (i == res.length - 1) {
                this.chartOptions1.series[0].data = [];
                this.chartOptions1.series[0].colors = [];
                this.chartOptions1.title.text = res[i]._id;
                for (let j = 0; j < res[i].series.length; j++) {
                  this.chartOptions1.series[0].colors.push(
                    res[i].series[j].tag == "red"
                      ? "#F44336"
                      : res[i].series[j].tag == "green"
                      ? "#4CAF50"
                      : res[i].series[j].tag == "yellow"
                      ? "#ffeb3b"
                      : res[i].series[j].tag == "gray"
                      ? "lightgray"
                      : res[i].series[j].tag
                  );
                  this.chartOptions1.series[0].data.push({
                    name: colorTitles[res[i].series[j].tag],
                    y: res[i].series[j].count,
                  });
                }
              }
              if (i == res.length - 2) {
                this.chartOptions2.series[0].data = [];
                this.chartOptions2.series[0].colors = [];
                this.chartOptions2.title.text = res[i]._id;
                for (let j = 0; j < res[i].series.length; j++) {
                  this.chartOptions2.series[0].colors.push(
                    res[i].series[j].tag == "red"
                      ? "#F44336"
                      : res[i].series[j].tag == "green"
                      ? "#4CAF50"
                      : res[i].series[j].tag == "yellow"
                      ? "#ffeb3b"
                      : res[i].series[j].tag == "gray"
                      ? "lightgray"
                      : res[i].series[j].tag
                  );
                  this.chartOptions2.series[0].data.push({
                    name: colorTitles[res[i].series[j].tag],
                    y: res[i].series[j].count,
                  });
                }
              }
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$refs.chart1.chart.hideLoading();
            this.$refs.chart2.chart.hideLoading();
            this.$refs.barChart.chart.hideLoading();
            if (error.response != undefined && error.response.status === 401) {
              this.tokenDialog = true;
            } else {
              if (error.response) {
                console.log(error.response.data.message);
              } else {
                console.log(error);
              }
            }
          });

        this.getAllTagReports({ _id: this.$route.params.id })
          .then((res) => {
            this.$refs.chart3.chart.hideLoading();
            console.log(res);
            this.chartOptions3.series[0].data = [];
            this.chartOptions3.series[0].colors = [];
            for (let j = 0; j < res.length; j++) {
              this.chartOptions3.series[0].colors.push(
                res[j]._id == "red"
                  ? "#F44336"
                  : res[j]._id == "green"
                  ? "#4CAF50"
                  : res[j]._id == "yellow"
                  ? "#ffeb3b"
                  : res[j]._id == "gray"
                  ? "lightgray"
                  : res[j]._id
              );
              this.chartOptions3.series[0].data.push({
                name: colorTitles[res[j]._id],
                y: res[j].count,
              });
            }
          })
          .catch((error) => {
            this.$refs.chart3.chart.hideLoading();
            console.log(error);
          });
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });

    // this.getClient(this.$route.params.id).then((res) => {
    //   this.profile = res;
    // });
  },
};
</script>
<style scoped>
.item-title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 24px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
</style>
