<template>
  <div class="elevation-6 profile rounded-lg" v-viewer="options">
    <div v-if="preview" style="width: 140px; height: 140px;">
      <img
        :src="selectedFile ? selectedImage : image ? image : placeholderImage"
        :data-source="
          selectedFile ? selectedImage : image ? image : placeholderImage
        "
        class="image"
        alt="Not available"
        v-if="preview"
      />
    </div>
    <v-img
      :src="selectedFile ? selectedImage : image ? image : placeholderImage"
      alt="Avatar"
      width="140"
      height="140"
      class="rounded-lg"
      v-else
    />
    <v-btn
      color="primary"
      class="edit_button"
      fab
      dark
      x-small
      :loading="isSelectingPhoto"
      @click="onEditButtonClick"
      v-if="editable"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn
      color="primary"
      class="delete_button"
      fab
      dark
      x-small
      :loading="isSelectingPhoto"
      @click="onDeleteImage"
      v-if="editable && (image || selectedFile) && deletable"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
  </div>
</template>
<script>
import Viewer from "v-viewer";
import Vue from "vue";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer, {
  debug: false,
  defaultOptions: {
    zIndex: 9999,
  },
});

export default {
  props: {
    image: {
      type: String,
    },
    onImageSelected: {
      type: Function,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 100,
    },
    placeholderImage: {
      type: String,
      default: require("@/assets/default.jpg"),
    },
    preview: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    onDelete: Function,
  },
  data() {
    return {
      selectedFile: null,
      isSelectingPhoto: false,
      selectedImage: null,
      options: {
        toolbar: true,
        scalable: false,
        title: false,
        url: "data-source",
      },
    };
  },
  methods: {
    onEditButtonClick() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        this.selectedImage = URL.createObjectURL(this.selectedFile);
        this.onImageSelected(this.selectedFile);
      }
    },
    onDeleteImage() {
      this.selectedFile = null;
      this.selectedImage = null;
      this.onDelete();
    },
  },
};
</script>
<style scoped>
.profile {
  overflow: hidden;
  position: relative;
}
.edit_button {
  position: absolute;
  top: 5px;
  right: 5px;
}
.delete_button {
  position: absolute;
  top: 45px;
  right: 5px;
}
.image {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
</style>
